/* carousel image dark overlay */
.carousel-item{
    background-color: black;
}
.carousel-item img{
    opacity: 0.4;
}

.carousel-item,
.carousel-item img {
    height: 600px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .carousel-inner {
        height: 400px;
    }

    .carousel-item,
    .carousel-item img {
        height: 400px;
        width: 100%;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {
    .carousel-inner {
        height: 160px;
    }

    .carousel-item,
    .carousel-item img {
        height: 160px;
        width: 100%
    }
}